import React, { useState, useEffect, useReducer, useContext } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { i18n } from "../../translate/i18n";
import IconButton from "@material-ui/core/IconButton";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import TableManager from "../../components/TableManager/TableManager";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import TableWhatsAppIcon from "../../assets/icon/TableWhatsAppIcon";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import { socketConnection } from "../../services/socket";
import ArrowUpIcon from "../../assets/icon/ArrowUpIcon";
import AddIcon from "../../assets/icon/AddIcon";
import ArrowDownIcon from "../../assets/icon/ArrowDownIcon";
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import ImportExcelButton from "../../components/ImportExcelButton";
import toastError from "../../errors/toastError";
import NewTicketModal from "../../components/NewTicketModal";
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "90%",
    },
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CONTACTS":
      return [
        ...state,
        ...action.payload.filter(
          (contact) => !state.find((c) => c.id === contact.id)
        ),
      ];
    case "UPDATE_CONTACT":
      return state.map((c) =>
        c.id === action.payload.id ? action.payload : c
      );
    case "DELETE_CONTACT":
      return state.filter((c) => c.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const ContactsTable = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [importConfirmOpen, setImportConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [importOption, setImportOption] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    fetchContacts();
  }, [searchParam, page, rowsPerPage]);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/contacts/", {
        params: { 
          searchParam,
          pageNumber: page + 1,
          limit: rowsPerPage
        },
      });
      
      dispatch({ type: "RESET" });
      dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
      setTotalCount(data.count);
      setHasMore(data.hasMore);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
    setPageNumber(1);
  };

  const handleOpenDeleteModal = (contact) => {
    setDeletingContact(contact);
    setDeleteConfirmOpen(true);
  };

  const handleOpenImportModal = () => {
    setImportOption(null);
    setImportConfirmOpen(true);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
      dispatch({ type: "DELETE_CONTACT", payload: contactId });
      await fetchContacts(); // Recargar contactos después de eliminar
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setDeleteConfirmOpen(false);
  };

  const handleimportContact = async () => {
    setIsImporting(true);
    setImportConfirmOpen(false);
    try {
      await api.post("/contacts/import");
      toast.success(i18n.t("contacts.toasts.importSuccess"));
      await fetchContacts();
    } catch (err) {
      toastError(err);
    } finally {
      setIsImporting(false);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = (hasUpdated = false) => {
    setSelectedContactId(null);
    setContactModalOpen(false);
    if (hasUpdated) {
      setPageNumber(1);
      dispatch({ type: "RESET" });
      fetchContacts();
    }
  };
  const handleDownloadTemplate = () => {
    // Datos de ejemplo para la plantilla
    const templateData = [
      {
        name: "Nombre Ejemplo",
        number: "5491112345678",
        email: "ejemplo@email.com",
      },
    ];

    // Crear un nuevo libro de trabajo
    const wb = XLSX.utils.book_new();
    
    // Convertir los datos a una hoja de cálculo
    const ws = XLSX.utils.json_to_sheet(templateData, {
      header: ["name", "number", "email"]
    });

    // Ajustar el ancho de las columnas
    const wscols = [
      { wch: 20 }, // name
      { wch: 15 }, // number
      { wch: 25 }, // email
    ];
    ws['!cols'] = wscols;

    // Agregar la hoja al libro
    XLSX.utils.book_append_sheet(wb, ws, "Plantilla");

    // Generar el archivo y descargarlo
    XLSX.writeFile(wb, "plantilla_contactos.xlsx");
  };

  const primaryButtons = [
    {
      label: "Agregar Contacto",
      onClick: handleOpenContactModal,
      icon: <AddIcon fill="#000" />,
    },
  ];

  const secondaryButtons = [
    {
      label: "Importar Contactos",
      onClick: handleOpenImportModal,
      icon: <ArrowUpIcon fill={theme.palette.primary.main} />,
    },
  ];
  const csvData = {
    icon: <ArrowDownIcon fill={theme.palette.primary.main} />,
    label: "Exportar Contactos",
    data: contacts.map((contact) => ({
      name: contact.name,
      number: contact.number,
      email: contact.email,
    })),
    separator: ";",
    filename: "Contactos.csv",
  };
  const columns = [
    {
      field: "avatar",
      headerName: "Nombre",
      headerStyle: { fontWeight: "400" },
    },
    {
      field: "number",
      headerName: "WhatsApp",
      align: "center",
      width: "25%",
      headerStyle: { fontWeight: "400" },
    },
    {
      field: "email",
      headerName: "Correo Electrónico",
      align: "center",
      width: "25%",
      headerStyle: { fontWeight: "400" },
    },
  ];

  const actionCellStyle = {
    width: "12%",
  };

  const actionButtons = [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setContactTicket(row);
          setNewTicketModalOpen(true);
        }}
      >
        <TableWhatsAppIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton size="small" onClick={() => hadleEditContact(row.id)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton size="small" onClick={() => handleOpenDeleteModal(row)}>
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <ConfirmationModal
          title="Eliminar Contacto"
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          onConfirm={() => handleDeleteContact(deletingContact?.id)}
        >
          ¿Estás seguro de que deseas eliminar este contacto?
        </ConfirmationModal>

        <ContactModal
          open={contactModalOpen}
          onClose={handleCloseContactModal}
          aria-labelledby="form-dialog-title"
          contactId={selectedContactId}
        ></ContactModal>

        <NewTicketModal
          modalOpen={newTicketModalOpen}
          initialContact={contactTicket}
          onClose={(ticket) => {
            handleCloseOrOpenTicket(ticket);
          }}
        />

        <ConfirmationModal
          title="Importar Contactos"
          open={importConfirmOpen}
          onClose={() => setImportConfirmOpen(false)}
          maxWidth="lg"
          showConfirmButton={false}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ padding: '20px' }}
          >
            <Grid 
              item 
              xs={12} 
              sm={12} 
              md={4} 
              style={{ 
                textAlign: "center",
                marginBottom: { xs: '10px', sm: '10px', md: '0' }
              }}
            >
              <Button
                onClick={handleDownloadTemplate}
                variant="contained"
                color="primary"
                disabled={isImporting}
                style={{
                  width: "100%",
                  height: '45px',
                  whiteSpace: "nowrap",
                  fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
                }}
              >
                Descargar Plantilla
              </Button>
            </Grid>
            <Grid 
              item 
              xs={12} 
              sm={12} 
              md={4} 
              style={{ 
                textAlign: "center",
                marginBottom: { xs: '10px', sm: '10px', md: '0' }
              }}
            >
              <ImportExcelButton
                endpoint="/contacts/import-excel"
                onSuccess={(data) => {
                  setIsImporting(true);
                  toast.success(i18n.t("contacts.toasts.importSuccess"));
                  dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                  setImportConfirmOpen(false);
                  setIsImporting(false);
                }}
                onError={(err) => {
                  setIsImporting(true);
                  toastError(err);
                  setImportConfirmOpen(false);
                  setIsImporting(false);
                }}
                buttonText="Importar Excel"
                variant="contained"
                color="primary"
                disabled={isImporting}
                style={{
                  width: "100%",
                  height: '45px',
                  fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
                }}
              />
            </Grid>
            <Grid 
              item 
              xs={12} 
              sm={12} 
              md={4} 
              style={{ 
                textAlign: "center"
              }}
            >
              <Button
                onClick={handleimportContact}
                variant="contained"
                color="primary"
                disabled={isImporting}
                style={{
                  width: "100%",
                  height: '45px',
                  whiteSpace: "nowrap",
                  fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
                }}
              >
                {isImporting ? "Importando..." : "Importar WhatsApp"}
              </Button>
            </Grid>
          </Grid>
        </ConfirmationModal>

        <div className={classes.tableContainer}>
          <HeaderManager
            title="Contactos"
            primaryButtons={primaryButtons}
            secondaryButtons={secondaryButtons}
            onSearch={handleSearch}
            csvData={csvData}
          />
          <TableManager
            columns={columns}
            actionCellStyle={actionCellStyle}
            data={contacts}
            loading={loading}
            actionButtons={actionButtons}
            hasPagination={true}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      </div>
    </>
  );
};

export default ContactsTable;
