const  SquareCoffeIcon= ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="34" height="34" rx="10" fill="#BD3C58" fill-opacity="0.16"/>
<path d="M21.3425 15.8526V21.3426C21.3425 23.0901 19.925 24.5001 18.185 24.5001H12.6575C10.9175 24.5001 9.5 23.0826 9.5 21.3426V15.8526C9.5 14.1051 10.9175 12.6951 12.6575 12.6951H18.185C19.925 12.6951 21.3425 14.1126 21.3425 15.8526Z" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.125 11V9.6875" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.125 11V9.6875" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.125 11V9.6875" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.5003 17.8699C24.5003 19.6099 23.0828 21.0274 21.3428 21.0274V14.7124C23.0828 14.7124 24.5003 16.1224 24.5003 17.8699Z" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 17H21.1325" stroke="#BD3C58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
  );
};

export default SquareCoffeIcon;
