import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import { toast } from 'react-toastify';
import api from '../../services/api';

const ImportExcelButton = ({ 
  endpoint, 
  onSuccess, 
  onError, 
  buttonText, 
  successMessage, 
  errorMessage,
  color = "#0F9D58",
  acceptedFileTypes = '.xlsx, .xls, .csv',
  variant,
  style,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(successMessage);
      if (onSuccess) onSuccess(response.data);
    } catch (err) {
      toast.error(errorMessage);
      if (onError) onError(err);
    }
  }, [endpoint, onSuccess, onError, successMessage, errorMessage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxFiles: 1,
  });

  const buttonStyles = {
    backgroundColor: isDragActive ? 'transparent' : "#0F9D58",
    color: isDragActive ? '#000' : '#FFFFFF',
    transition: 'all 0.3s ease',
    width: '100%',
    height: '45px',
    whiteSpace: 'nowrap',
    fontSize: isMobile ? '0.8rem' : isTablet ? '0.9rem' : '1rem',
    padding: isMobile ? '6px 12px' : '8px 16px',
    ...style
  };

  const containerStyles = {
    display: 'inline-block',
    width: '100%',
    margin: isMobile ? '5px 0' : '0',
  };

  return (
    <div {...getRootProps()} style={containerStyles}>
      <input {...getInputProps()} />
      <Button 
        variant={isDragActive ? 'outlined' : variant || 'contained'}
        style={buttonStyles}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ImportExcelButton;