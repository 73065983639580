const ErrorIcon = ({ fill = "#FF5858", width = "18", height = "18" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99992 14.6668C11.6666 14.6668 14.6666 11.6668 14.6666 8.00016C14.6666 4.3335 11.6666 1.3335 7.99992 1.3335C4.33325 1.3335 1.33325 4.3335 1.33325 8.00016C1.33325 11.6668 4.33325 14.6668 7.99992 14.6668Z" stroke={fill} stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.11328 9.88661L9.88661 6.11328" stroke={fill} stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.88661 9.88661L6.11328 6.11328" stroke={fill} stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default ErrorIcon