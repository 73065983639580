const CloseIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 17.5L17 7.5" stroke="#AEADAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 17.5L7 7.5" stroke="#AEADAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



  );
};

export default CloseIcon;
