const SearchIcon = ({ fill = "none", width = "18", height = "18", strokeWidth= "1.5" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke={fill} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 22L20 20" stroke={fill} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export default SearchIcon