const DownloadIcon = ({ fill = "none", strokeWidth= "1.5" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.5V6.75L10.5 5.25" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 6.75L7.5 5.25" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.48486 9.75H4.79236C5.07736 9.75 5.33236 9.9075 5.45986 10.1625L6.33736 11.9175C6.59236 12.4275 7.10986 12.75 7.67986 12.75H10.3274C10.8974 12.75 11.4149 12.4275 11.6699 11.9175L12.5474 10.1625C12.6749 9.9075 12.9374 9.75 13.2149 9.75H16.4849" stroke={fill} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.25 3.09766C2.595 3.48766 1.5 5.04766 1.5 8.25016V11.2502C1.5 15.0002 3 16.5002 6.75 16.5002H11.25C15 16.5002 16.5 15.0002 16.5 11.2502V8.25016C16.5 5.04766 15.405 3.48766 12.75 3.09766" stroke={fill} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default DownloadIcon