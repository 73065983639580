const SquareClockIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="10" fill="#B05C38" fill-opacity="0.16" />
      <path
        d="M24.5 17C24.5 21.14 21.14 24.5 17 24.5C12.86 24.5 9.5 21.14 9.5 17C9.5 12.86 12.86 9.5 17 9.5C21.14 9.5 24.5 12.86 24.5 17Z"
        stroke="#B05C38"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.7824 19.3851L17.4574 17.9976C17.0524 17.7576 16.7224 17.1801 16.7224 16.7076V13.6326"
        stroke="#B05C38"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SquareClockIcon;
