const SendIcon = ({
  fill = "#909090",
  width = "24",
  height = "25",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50978 4.73013L18.0698 9.01013C21.9098 10.9301 21.9098 14.0701 18.0698 15.9901L9.50978 20.2701C3.74978 23.1501 1.39978 20.7901 4.27978 15.0401L5.14978 13.3101C5.36978 12.8701 5.36978 12.1401 5.14978 11.7001L4.27978 9.96013C1.39978 4.21013 3.75978 1.85013 9.50978 4.73013Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.43994 12.5H10.8399"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SendIcon;
