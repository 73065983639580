const EmoteIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V7.25C16.5 3.5 15 2 11.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.625 7.8125C12.2463 7.8125 12.75 7.30882 12.75 6.6875C12.75 6.06618 12.2463 5.5625 11.625 5.5625C11.0037 5.5625 10.5 6.06618 10.5 6.6875C10.5 7.30882 11.0037 7.8125 11.625 7.8125Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.375 7.8125C6.99632 7.8125 7.5 7.30882 7.5 6.6875C7.5 6.06618 6.99632 5.5625 6.375 5.5625C5.75368 5.5625 5.25 6.06618 5.25 6.6875C5.25 7.30882 5.75368 7.8125 6.375 7.8125Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.3 10.4746H11.7C12.075 10.4746 12.375 10.7746 12.375 11.1496C12.375 13.0171 10.8675 14.5246 9 14.5246C7.1325 14.5246 5.625 13.0171 5.625 11.1496C5.625 10.7746 5.925 10.4746 6.3 10.4746Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EmoteIcon;
