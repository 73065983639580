import axios from "axios";


const api = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL ,
	withCredentials: true,
});
export const apione = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL_LOGIN ,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL_LOGIN 
});

export default api;
