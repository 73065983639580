import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles, Avatar, Tooltip, useMediaQuery, useTheme, TablePagination } from '@material-ui/core';
import TableRowSkeleton from "../../components/TableRowSkeleton";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 20,
    borderRadius: 15,
    margin: 'auto',
    [theme.breakpoints.down("sm")]: {
      margin: 'auto'
    },
    backgroundColor: (props) => props.backgroundColor || theme.palette.tabHeaderBackground,
  },
  tableTitleCell: {
    fontSize: '18px',
    color: theme.palette.textPrimary,
    padding: '10px 20px',
    textAlign: 'left',
    backgroundColor: theme.palette.tabHeaderBackground,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableHeaderCell: {
    borderColor: 'rgba(0, 0, 0, 4%)',
    fontSize: '14px',
    color: theme.palette.headerText,
    textAlign: 'left',
    '&:first-child': {
      paddingLeft:theme.spacing(5),
    },
  },
  tableCell: {
    flex: 1,
    fontWeight: '400',
    color: theme.palette.textPrimary,
    fontSize: '14px',
    textAlign: 'left',
    minWidth: '100px',
    borderColor: 'rgba(0, 0, 0, 4%)',
    '&:first-child': {
      paddingLeft:theme.spacing(5),
    },
  },
  cellContent: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tooltipArrow: {
    left: '0px !important',
    transform: 'translateX(10px) !important',
    [theme.breakpoints.down("sm")]: {
      left: 'unset !important',
      transform: 'unset !important',
    },
  },
  avatar: {
    marginRight: 10,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  actionCell: {
    display: 'flex',
    textAlign: 'left',
    borderBottom: 'none',
  },
  actionButtonContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  }
}));

const TableManager = ({
  title,
  columns = [],
  data = [],
  actionButtons = [],
  actionCellStyle = {},
  loading,
  backgroundColor,
  page = 0,
  rowsPerPage = 15,
  totalCount = 0,
  onPageChange,
  onRowsPerPageChange,
  hasPagination = false
}) => {
  const classes = useStyles({ backgroundColor }); 
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));

  const getCellValue = (row, field) => {
    const fields = field?.split('.');
    return fields.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, row);
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength - 3) + '...';
    }
    return text;
  };

  const renderCell = (row, column) => {
    const value = getCellValue(row, column.field);

    if (column.renderCell) {
      return column.renderCell({ row, value });
    }

    if (column.field === 'avatar') {
      return (
        <Tooltip title={row.name}>
          <div className={classes.nameCell}>
            <Avatar src={row.profilePicUrl} className={classes.avatar} />
            <span className={classes.cellContent}>{truncateText(row.name, 20)}</span>
          </div>
        </Tooltip>
      );
    }

    const displayValue = typeof value === 'string' ? truncateText(value, 30) : value;

    return (
      <Tooltip 
        title={value}
        classes={{
          tooltip: classes.tooltip,
          arrow: !isSmOrLess ? classes.tooltipArrow : '',
        }}
        placement="bottom-start"
        arrow
      >
        <span className={classes.cellContent}>{displayValue}</span>
      </Tooltip>
    );
  };

  // Verificar si alguna columna tiene headerName
  const hasHeader = columns.some((column) => column.headerName);

  const totalColumns = columns.length + (actionButtons.length > 0 ? 1 : 0);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        {(title || hasHeader) && (
          <TableHead>
            {title && (
              <TableRow>
                <TableCell colSpan={totalColumns} className={classes.tableTitleCell}>
                  {title}
                </TableCell>
              </TableRow>
            )}
            {hasHeader && (
              <TableRow>
                {columns.map((column) => (
                  <TableCell 
                    key={column.field} 
                    className={classes.tableHeaderCell}
                    style={{ ...column.headerStyle, width: column.width }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
                {actionButtons.length > 0 && (
                  <TableCell 
                    className={classes.tableHeaderCell} 
                    style={{ ...actionCellStyle }}
                  />
                )}
              </TableRow>
            )}
          </TableHead>
        )}
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column) => (
                <TableCell 
                  key={column.field} 
                  className={classes.tableCell}
                  style={{ ...column.cellStyle, width: column.width }}
                >
                  {renderCell(row, column)}
                </TableCell>
              ))}
              {actionButtons.length > 0 && (
                <TableCell 
                  className={classes.actionCell}
                  style={{ ...actionCellStyle }}
                >
                  <div className={classes.actionButtonContainer}>
                    {actionButtons.map((renderButton, index) => (
                      <span key={index}>
                        {renderButton({ row })}
                      </span>
                    ))}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
          {loading && <TableRowSkeleton avatar columns={totalColumns} />}
        </TableBody>
      </Table>
      
      {hasPagination && (
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => {
            const adjustedFrom = from ;
            const adjustedTo = Math.min(to, count);
            return `${adjustedFrom}-${adjustedTo} de ${count}`;
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          className={classes.paginationContainer}
        />
      )}
    </TableContainer>
  );
};

export default TableManager;
