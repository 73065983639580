import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	dialog: {
	  backgroundColor: theme.palette.tabHeaderBackground,
	},
	btnWrapper: {
	  position: "relative",
	  borderRadius: 10,
	  padding: 10,
	  width: 100,
	},
	deleteButton: {
	  backgroundColor: theme.palette.deleteButton,
	  width: 180,
	  color: "white",
	  fontSize:14
	},
  }));
  
  const ConfirmationModal = ({ title, children, open, onClose, onConfirm, confirmButtonText, showConfirmButton = true }) => {
	const classes = useStyles();
  
	return (
	  <Dialog
		open={open}
		onClose={() => onClose(false)}
		aria-labelledby="confirm-dialog"
		classes={{ paper: classes.dialog }}
	  >
		<DialogTitle id="confirm-dialog">{title}</DialogTitle>
		<DialogContent dividers>
		  <Typography>{children}</Typography>
		</DialogContent>
		<DialogActions>
		  <Button
			variant="outlined"
			onClick={() => onClose(false)}
			color="secondary"
			className={classes.btnWrapper}
		  >
			{i18n.t("confirmationModal.buttons.cancel")}
		  </Button>
		  {showConfirmButton && (
		  <Button
		  variant="contained"
		  onClick={() => {
			onClose(false);
			onConfirm();
		  }}
		  className={`${classes.btnWrapper} ${classes.deleteButton}`}
		>
			 { confirmButtonText? confirmButtonText : i18n.t("confirmationModal.buttons.confirm")}
		</Button>
		  )}
		</DialogActions>
	  </Dialog>
	);
  };
  
  export default ConfirmationModal;