const SquareWhatsappIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="12" fill="#25D366" fill-opacity="0.12"/>
<path d="M22.9 36.6C24.4 37.5 26.2 38 28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 29.8 18.5 31.5 19.3 33L18.4404 36.306C18.2457 37.0549 18.9389 37.7317 19.683 37.5191L22.9 36.6Z" stroke="#25D366" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.5 30.8485C32.5 31.0105 32.4639 31.177 32.3873 31.339C32.3107 31.501 32.2116 31.654 32.0809 31.798C31.86 32.041 31.6167 32.2165 31.3418 32.329C31.0714 32.4415 30.7784 32.5 30.4629 32.5C30.0033 32.5 29.512 32.392 28.9937 32.1715C28.4755 31.951 27.9572 31.654 27.4434 31.2805C26.9251 30.9025 26.4339 30.484 25.9652 30.0205C25.501 29.5525 25.0819 29.062 24.7078 28.549C24.3383 28.036 24.0408 27.523 23.8245 27.0145C23.6082 26.5015 23.5 26.011 23.5 25.543C23.5 25.237 23.5541 24.9445 23.6622 24.6745C23.7704 24.4 23.9417 24.148 24.1805 23.923C24.469 23.6395 24.7844 23.5 25.1179 23.5C25.2441 23.5 25.3703 23.527 25.483 23.581C25.6002 23.635 25.7038 23.716 25.7849 23.833L26.8305 25.3045C26.9116 25.417 26.9702 25.5205 27.0108 25.6195C27.0513 25.714 27.0739 25.8085 27.0739 25.894C27.0739 26.002 27.0423 26.11 26.9792 26.2135C26.9206 26.317 26.835 26.425 26.7268 26.533L26.3843 26.8885C26.3348 26.938 26.3122 26.9965 26.3122 27.0685C26.3122 27.1045 26.3167 27.136 26.3257 27.172C26.3393 27.208 26.3528 27.235 26.3618 27.262C26.4429 27.4105 26.5826 27.604 26.7809 27.838C26.9837 28.072 27.2 28.3105 27.4344 28.549C27.6778 28.7875 27.9121 29.008 28.151 29.2105C28.3853 29.4085 28.5791 29.5435 28.7323 29.6245C28.7549 29.6335 28.7819 29.647 28.8135 29.6605C28.8495 29.674 28.8856 29.6785 28.9261 29.6785C29.0028 29.6785 29.0613 29.6515 29.1109 29.602L29.4534 29.2645C29.5661 29.152 29.6743 29.0665 29.7779 29.0125C29.8816 28.9495 29.9852 28.918 30.0979 28.918C30.1835 28.918 30.2737 28.936 30.3728 28.9765C30.472 29.017 30.5756 29.0755 30.6883 29.152L32.18 30.2095C32.2972 30.2905 32.3783 30.385 32.4279 30.4975C32.473 30.61 32.5 30.7225 32.5 30.8485Z" stroke="#25D366" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>

  );
};

export default SquareWhatsappIcon;
