const UndoIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34766 13.7324H11.3477C13.4177 13.7324 15.0977 12.0524 15.0977 9.98242C15.0977 7.91242 13.4177 6.23242 11.3477 6.23242H3.09766"
        stroke="#909090"
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.82234 8.10758L2.90234 6.18758L4.82234 4.26758"
        stroke="#909090"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UndoIcon;
