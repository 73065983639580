import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import TableManager from "../../components/TableManager/TableManager";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import AddIcon from "../../assets/icon/AddIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_TAGS':
      return [...action.payload];
    case 'UPDATE_TAGS':
      const tagIndex = state.findIndex(tag => tag.id === action.payload.id);
      if (tagIndex !== -1) {
        state[tagIndex] = action.payload;
        return [...state];
      } else {
        return [action.payload, ...state];
      }
    case 'DELETE_TAG':
      return state.filter(tag => tag.id !== action.payload);
    case 'RESET':
      return [];
    default:
      return state;
  }
};

const Tags = () => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  const fetchTags = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/tags/", {
        params: { searchParam, pageNumber, kanban: 0 },
      });
      dispatch({ type: "LOAD_TAGS", payload: data.tags });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchTags]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tag });
      }
      
      if (data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tag });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TAG", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
      dispatch({ type: "DELETE_TAG", payload: tagId });
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setConfirmModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const primaryButtons = [
    { label: i18n.t("tags.buttons.add"), onClick: handleOpenTagModal, icon: <AddIcon fill='#000' /> },
  ];

  const columns = [
    { 
      field: 'name', 
      headerName: i18n.t("tags.table.name"),
      renderCell: ({ row }) => (
        <Chip
          variant="outlined"
          style={{
            backgroundColor: row.color,
            textShadow: "1px 1px 1px #000",
            color: "white",
          }}
          label={row.name}
          size="small"
        />
      ),
      headerStyle: {fontWeight: '400'}
      ,width: '40%'
    },
    { field: 'ticketsCount', headerName: i18n.t("tags.table.tickets"), headerStyle: {fontWeight: '400'},width: '40%' },
  ];

  const actionButtons = [
    ({row}) => (
      <IconButton size="small" onClick={() => handleEditTag(row)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({row}) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingTag(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
    <div className={classes.mainContainer} >
      <ConfirmationModal
        title={deletingTag && `${i18n.t("tags.confirmationModal.deleteTitle")}`}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteTag(deletingTag.id)}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        reload={fetchTags}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
        kanban={0}
      />
      <div className={classes.tableContainer}>
      <HeaderManager
        title={`${i18n.t("tags.title")}`}
        primaryButtons={primaryButtons}
        onSearch={handleSearch}
      />
      <TableManager
        columns={columns}
        data={tags}
        loading={loading}
        onScroll={loadMore}
        actionButtons={actionButtons}
      />
      </div>
      </div>
    </>
  );
};

export default Tags;