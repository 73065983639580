import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import DownArrow from "../../assets/icon/DownArrow";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000014',
        transition: 'border-color 0.2s ease-in-out',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    textShadow: "1px 1px 1px #000",
    color: "white",
  },
  icon: {
    right: theme.spacing(1),
    position: "absolute",
    pointerEvents: "none",
  },
}));

export function TagsFilter({ onFiltered }) {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadTags();
    }
    fetchData();
  }, []);

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: 10 }}>
      <FormControl className={classes.formControl}>
        <Autocomplete
          multiple
          size="small"
          options={tags}
          value={selecteds}
          onChange={(e, v) => onChange(v)}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                style={{
                  backgroundColor: option.color || "#eee",
                }}
                label={option.name}
                {...getTagProps({ index })}
                size="small"
                className={classes.chip}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Filtro por etiquetas"
              InputProps={{
                ...params.InputProps,
                classes: { root: classes.inputRoot },
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}