import React from "react";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Tickets from "../TicketsCustom"
import TicketAdvanced from "../TicketsAdvanced";
import { useMediaQuery, useTheme } from "@material-ui/core";

function TicketResponsiveContainer () {
    const theme = useTheme();
    const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));

    if (!isMdOrLess) {
        return <Tickets />;    
    }
    return <TicketAdvanced />
}

export default withWidth()(TicketResponsiveContainer);