import React from 'react';
import { Button, TextField, InputAdornment, Typography, Grid, Box } from '@material-ui/core';
import SearchIcon from "../../assets/icon/SearchIcon";
import { CSVLink } from 'react-csv';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    title: {
        fontSize: '2rem',
        fontWeight: 400,
        marginBottom: theme.spacing(2),
        color: theme.palette.textPrimary,

    },
    centered: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            justifyContent: 'flex-end',
        },
    },
    searchField: {
        backgroundColor: "transparent",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            width: '250px',
        },
        [theme.breakpoints.up('md')]: {
            width: '280px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.borderPhoneInput,
            },
            '&:hover fieldset': {
                borderColor: '#888',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#B0B0B0',
            },
        },
        '& input::placeholder': {
            color: theme.palette.headerText,
            opacity: 1,
            paddingLeft: "10px",
            fontSize: "17px",
        },
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
    button: {
        borderRadius: "12px",
        padding: '8px 16px',
        minWidth: '120px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 12px',
            fontSize: '0.8rem',
            minWidth: '100px',
        },
    },
    primaryButton: {
        backgroundColor: '#f8bf00',
        color: '#000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0ac00',
        },
    },
    secondaryButton: {
        border: "1px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main,
        border: "none",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#fff5db',
        },
    },
    csvButton: {
        border: "1px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main,
        border: "none",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#fff5db',
        },
    },
    iconLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
            marginRight: theme.spacing(1),
        },
    },
}));

const HeaderManager = ({
    title,
    titlePosition = 'flex-start',
    searchParam,
    onSearch,
    primaryButtons,
    secondaryButtons,
    csvData,
    placeholder,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const hideControls = titlePosition !== 'flex-start';

    return (
        <Box
            className={`${classes.headerContainer} ${titlePosition === 'center' ? classes.centered : ''}`}
        >
            <Typography className={classes.title} style={{ textAlign: `${titlePosition === 'center' && 'center'}` }}>
                {title}
            </Typography>
            {!hideControls && (
                <Box className={classes.controlsContainer}>
                    <Box className={classes.buttonsContainer}>
                        {secondaryButtons && secondaryButtons.map((btn, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                className={`${classes.button} ${classes.secondaryButton}`}
                                onClick={btn.onClick}
                            >
                                <Box className={classes.iconLabel}>
                                    {btn.icon}
                                    {btn.label}
                                </Box>
                            </Button>
                        ))}

                        {csvData && (
                            <CSVLink
                                separator=";"
                                filename={csvData.filename}
                                data={csvData.data}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    variant="outlined"
                                    className={`${classes.button} ${classes.csvButton}`}
                                >
                                    <Box className={classes.iconLabel}>
                                        {csvData.icon}
                                        {csvData.label}
                                    </Box>
                                </Button>
                            </CSVLink>
                        )}
                        
                        {onSearch && (
                            <TextField
                                placeholder={placeholder || "Buscar..."}
                                type="search"
                                value={searchParam}
                                onChange={onSearch}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fill={theme.palette.headerText} width='24px' height='24px' />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        backgroundColor: "transparent",
                                        borderRadius: "15px",
                                        borderColor: "#333",
                                        padding: "0px 18px",
                                        fontSize: "14px",
                                        height: "40px",
                               
                                    },
                                }}
                                className={classes.searchField}
                                inputProps={{
                                    style: {

                                    },
                                }}
                            />
                        )}

                        {primaryButtons && primaryButtons.map((btn, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                className={`${classes.button} ${classes.primaryButton}`}
                                onClick={btn.onClick}
                            >
                                <Box className={classes.iconLabel}>
                                    {btn.icon}
                                    {btn.label}
                                </Box>
                            </Button>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default HeaderManager;