import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import {
  Badge,
  Collapse,
  List,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import WhatsAppIcon from "../assets/icon/WhatsAppIcon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import DashBoardIcon from "../assets/icon/DashBoardIcon";
import TaskIcon from "../assets/icon/TaskIcon";
import ContactIcon from "../assets/icon/ContactIcon";
import CalendarIcon from "../assets/icon/CalendarIcon";
import ChatIcon from "../assets/icon/ChatIcon";
import TaskBoardIcon from "../assets/icon/TaskBoardIcon";
import TagsIcon from "../assets/icon/TagsIcon";
import FastIcon from "../assets/icon/FastIcon";
import CampaignsIcon from "../assets/icon/CampaignsIcon";
import UsersIcon from "../assets/icon/UsersIcon";
import SettingsIcon from "../assets/icon/SettingsIcon";
import HelpIcon from "../assets/icon/HelpIcon";
import TrayIcon from "../assets/icon/TrayIcon";
import ConnectionsIcon from "../assets/icon/ConnectionsIcon";
import TerminalIcon from "../assets/icon/TerminalIcon";
import DepartmentIcon from "../assets/icon/DepartmentIcon";
import SubscriptionIcon from "../assets/icon/SubscriptionIcon";
import TrayIconSide from "../assets/icon/TrayIconSide";

const useStyles = (drawerOpen) => {
  return makeStyles((theme) => ({
    root: {
      background: theme.palette.sidebarBackground,
      color: theme.palette.sideBarIcon,
      overflowY: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      height: "100vh",
      padding: "20px 0",
      margin: drawerOpen ? "0px 18px 0 18px" : "0px 10px 0 10px",
    },
    listItem: {
      paddingRight: "3px",
      marginTop: 2,
      borderRadius: "15px",
      display: "flex",
      alignItems: "center",
      transition: "background-color , border-radius",
      "&:hover": {
        backgroundColor: "rgba(255, 245, 218, 0.24)",
        color: theme.palette.primary.main,
        "& svg path": {
          stroke: theme.palette.primary.main,
          transition: "stroke ",
        },
      },
    },
    selected: {
      marginLeft: 1,
      backgroundColor: "rgba(255, 245, 218, 0.24)",
      borderRadius: "15px",
      color: theme.palette.primary.main,
      transition: "background-color , border-radius",
      "& svg path": {
        stroke: theme.palette.primary.main,
        transition: "stroke ",
      },
    },
    listItemText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    subheader: {
      color: theme.palette.text.secondary,
      margin: "10px 0 0 20px",
    },
    collapse: {
      paddingLeft: drawerOpen ? theme.spacing(3) : theme.spacing(1),
    },
  }))();
};

function ListItemLink(props) {
  const classes = useStyles();
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem button component={renderLink} className={className}>
      {icon ? (
        <Tooltip title={primary} arrow placement="right">
          <ListItemIcon className={classes.navBarIcon}>{icon}</ListItemIcon>
        </Tooltip>
      ) : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, drawerOpen } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [connectionBadged, setConnectionBadged] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);

  const location = useLocation();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length === whatsApps.length) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const commonItems = (
    <>
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary={i18n.t("mainDrawer.listItems.dashboard")}
            icon={<DashBoardIcon fill={theme.palette.sideBarIcon} />}
            className={`${classes.listItem} ${
              location.pathname === "/" ? classes.selected : ""
            }`}
          />
        )}
      />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon fill={theme.palette.sideBarIcon} />}
        className={`${classes.listItem} ${
          location.pathname === "/tickets" ? classes.selected : ""
        }`}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactIcon fill={theme.palette.sideBarIcon} />}
        className={`${classes.listItem} ${
          location.pathname === "/contacts" ? classes.selected : ""
        }`}
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<CalendarIcon fill={theme.palette.sideBarIcon} />}
        className={`${classes.listItem} ${
          location.pathname === "/schedules" ? classes.selected : ""
        }`}
      />

      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<FastIcon fill={theme.palette.sideBarIcon} />}
        className={`${classes.listItem} ${
          location.pathname === "/quick-messages" ? classes.selected : ""
        }`}
      />
      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<TagsIcon fill={theme.palette.sideBarIcon} />}
        className={`${classes.listItem} ${
          location.pathname === "/tags" ? classes.selected : ""
        }`}
      />

      <ListItemLink
        to="/connections"
        primary={i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
            <ConnectionsIcon fill={theme.palette.sideBarIcon} />
          </Badge>
        }
        className={`${classes.listItem} ${
          location.pathname === "/connections" ? classes.selected : ""
        }`}
      />
    </>
  );

  return (
    <div onClick={drawerClose} className={classes.root}>
      {commonItems}
      {/* Comentado: Embudos (kanban)
        <ListItemLink
            to='/kanban'
            primary={i18n.t("mainDrawer.listItems.kanban")}
            icon={<TaskBoardIcon fill={theme.palette.sideBarIcon} /> }
            className={`${classes.listItem} ${location.pathname === '/kanban' ? classes.selected : ''}`}
        />
        */}

      {/* Comentado: Chat interno
        <ListItemLink
            to="/chats"
            primary={i18n.t("mainDrawer.listItems.chats")}
            icon={
                <Badge color="secondary" variant="dot" invisible={invisible}>
                    <ChatIcon fill={theme.palette.sideBarIcon} />
                </Badge>
            }
            className={`${classes.listItem} ${location.pathname === '/chats' ? classes.selected : ''}`}
        />
        */}

      {/* Comentado: Tareas
        <ListItemLink
            to="/todolist"
            primary={i18n.t("mainDrawer.listItems.todolist")}
            icon={<TaskIcon fill={theme.palette.sideBarIcon} />}
            className={`${classes.listItem} ${location.pathname === '/todolist' ? classes.selected : ''}`}
        />
        */}
      {/*  {showCampaigns && (
            <>
                <ListItem
                    button
                    onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                    className={`${classes.listItem} ${['/campaigns', '/contact-lists', '/campaigns-config'].includes(location.pathname) ? classes.selected : ''}`}
                >
                    <ListItemIcon>
                        <CampaignsIcon fill={theme.palette.sideBarIcon}  />
                    </ListItemIcon>
                    <ListItemText
                        primary={i18n.t("mainDrawer.listItems.campaigns")}
                    />
                    {openCampaignSubmenu ? (
                        <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                    )}
                </ListItem>
                <Collapse
                    className={classes.collapse}
                    in={openCampaignSubmenu}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItem onClick={() => history.push("/campaigns")} button 
                        selected={location.pathname === '/campaigns'} 
                        className={`${classes.listItem} ${location.pathname === '/campaigns' ? classes.selected : ''}`} 
                        >
                            <ListItemIcon>
                            <CalendarIcon fill={theme.palette.sideBarIcon} />
                            </ListItemIcon>
                            <ListItemText primary="Campañas" />
                        </ListItem>
                        <ListItem
                            onClick={() => history.push("/contact-lists")}
                            button
                            selected={location.pathname === '/contact-lists'}
                            className={`${classes.listItem} ${location.pathname === '/contact-lists' ? classes.selected : ''}`} 
                        >
                            <ListItemIcon>
                                <UsersIcon fill={theme.palette.sideBarIcon}  />
                            </ListItemIcon>
                            <ListItemText primary="Listas de contactos" />
                        </ListItem>
                        <ListItem
                            onClick={() => history.push("/campaigns-config")}
                            button
                            selected={location.pathname === '/campaigns-config'}
                            className={`${classes.listItem} ${location.pathname === '/campaigns-config' ? classes.selected : ''}`} 
                        >
                            <ListItemIcon>
                                <SettingsIcon fill={theme.palette.sideBarIcon} />
                            </ListItemIcon>
                            <ListItemText primary="Ajustes - deprecable" />
                        </ListItem>
                    </List>
                </Collapse>
            </>
        )}
        */}

      {/* {user.profile === "admin" && (
            <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<TrayIconSide fill={theme.palette.sideBarIcon}  />}
                className={`${classes.listItem} ${location.pathname === '/announcements' ? classes.selected : ''}`} 
            />
        )} */}

      {/* Comentado: Ayuda
        <ListItemLink
            to="/helps"
            primary={i18n.t("mainDrawer.listItems.helps")}
            icon={<HelpIcon fill={theme.palette.sideBarIcon} />}
            className={`${classes.listItem} ${location.pathname === '/helps' ? classes.selected : ''}`} 
        />
        */}
      {user.profile === "admin" && (
        <>
          <Divider />
          <ListSubheader inset style={{ color: theme.palette.sideBarIcon }}>
            {i18n.t("mainDrawer.listItems.administration")}
          </ListSubheader>

          <ListItemLink
            to="/Departaments"
            primary={i18n.t("mainDrawer.listItems.queues")}
            icon={<DepartmentIcon fill={theme.palette.sideBarIcon} />}
            className={`${classes.listItem} ${
              location.pathname === "/queues" ? classes.selected : ""
            }`}
          />

          <ListItemLink
            to="/users"
            primary={i18n.t("mainDrawer.listItems.users")}
            icon={<UsersIcon fill={theme.palette.sideBarIcon} />}
            className={`${classes.listItem} ${
              location.pathname === "/users" ? classes.selected : ""
            }`}
          />
          {/* 
            <ListItemLink
              to="/messages-api"
              primary={i18n.t("mainDrawer.listItems.messagesAPI")}
              icon={<TerminalIcon fill={theme.palette.sideBarIcon} />}
              className={`${classes.listItem} ${
                location.pathname === "/messages-api" ? classes.selected : ""
              }`}
            /> */}

          {/* <ListItemLink
              to="/subscription"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<SubscriptionIcon fill={theme.palette.sideBarIcon} />}
              className={`${classes.listItem} ${
                location.pathname === "/subscription" ? classes.selected : ""
              }`}
            /> */}

          {/* <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsIcon fill={theme.palette.sideBarIcon} />}
              className={`${classes.listItem} ${
                location.pathname === "/settings" ? classes.selected : ""
              }`}
            /> */}
        </>
      )}
    </div>
  );
};

export default MainListItems;
