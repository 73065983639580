import React from "react";

import { green, red } from '@material-ui/core/colors';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import TableManager from "../TableManager/TableManager";
import { RatingBox } from "../RatingBox/RatingBox";
import { useTheme } from "@material-ui/core";
import ButtonActiveIcon from "../../assets/icon/ButtonActiveIcon";
const TableAttendantsManager = ({ loading, attendants }) => {
    const theme = useTheme();
    const columns = [
      { field: 'name', headerName: 'Nombre', width: 200 },
      { 
        field: 'rating', 
        headerName: 'Evaluaciones', 
        width: 150, 
        renderCell: ({ row }) => (
          <RatingBox rating={row.rating} />
        ),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' }
      },
      { 
        field: 'avgSupportTime', 
        headerName: 'T.M. de Servicio', 
        width: 150,
        renderCell: ({ row }) => (
          formatTime(row.avgSupportTime)
        ),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' }
      },
      { 
        field: 'online', 
        headerName: 'Estado (Actual)', 
        width: 150, 
        renderCell: ({ row }) => (
          row.online ? 
            <ButtonActiveIcon /> 
            : <ErrorIcon style={{ color: red[600] }} />
        ),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' }
      }
    ];
  
    const formatTime = (minutes) => {
      return moment().startOf('day').add(minutes, 'minutes').format('HH[h] mm[m]');
    };
  
    return (
      <TableManager
      backgroundColor={theme.palette.inputBackground}
        columns={columns}
        data={attendants}
        loading={loading}
      />
    );
  };
  
  export default TableAttendantsManager;
  