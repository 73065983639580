import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const TransferSchema = Yup.object().shape({
  selectedUser: Yup.object().nullable().required("Requerido"),
  selectedQueue: Yup.string().required("Requerido"),
});

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid, reloadTickets }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const isMounted = useRef(true);
  const [queueDisabled, setQueueDisabled] = useState(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const loadQueues = async () => {
        try {
          const list = await findAllQueues();
          setAllQueues(Array.isArray(list) ? list : []);
          setQueues(Array.isArray(list) ? list : []);
        } catch (error) {
          console.error("Error al cargar las colas:", error);
          setAllQueues([]);
          setQueues([]);
        }
      };
      loadQueues();
    }
  }, []);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleSaveTicket = async (values) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticketid}`, {
        userId: values.selectedUser.id,
        queueId: values.selectedQueue,
        status: "open",
      });
      setLoading(false);
      handleClose();
      if (typeof reloadTickets === "function") {
        reloadTickets();
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <Formik
        initialValues={{
          selectedUser: null,
          selectedQueue: "",
        }}
        validationSchema={TransferSchema}
        onSubmit={(values, actions) => {
          handleSaveTicket(values);
          actions.setSubmitting(false);
        }}
      >
        {({ touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {i18n.t("transferTicketModal.title")}
            </DialogTitle>
            <DialogContent dividers>
              <Autocomplete
                style={{ width: 300, marginBottom: 20 }}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(e, newValue) => {
                  setFieldValue("selectedUser", newValue);
                  setQueues(newValue ? newValue.queues : allQueues);
                  setFieldValue("selectedQueue", "");
                  setSelectedQueue("");
                  setQueueDisabled(!newValue);
                }}
                options={options}
                filterOptions={filterOptions}
                freeSolo
                autoHighlight
                noOptionsText={i18n.t("transferTicketModal.noOptions")}
                loading={loading}
                renderInput={(params) => (
                  <Field
                    as={TextField}
                    {...params}
                    name="selectedUser"
                    label={i18n.t("transferTicketModal.fieldLabel")}
                    variant="outlined"
                    autoFocus
                    onChange={(e) => setSearchParam(e.target.value)}
                    error={touched.selectedUser && Boolean(errors.selectedUser)}
                    helperText={touched.selectedUser && errors.selectedUser}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <FormControl
                variant="outlined"
                className={classes.maxWidth}
                error={touched.selectedQueue && Boolean(errors.selectedQueue)}
              >
                <InputLabel>
                  {i18n.t("transferTicketModal.fieldQueueLabel")}
                </InputLabel>
                <Select
                  as={Select}
                  name="selectedQueue"
                  value={selectedQueue}
                  onChange={(e) => {
                    setSelectedQueue(e.target.value);
                    setFieldValue("selectedQueue", e.target.value);
                  }}
                  label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
                  disabled={queueDisabled}
                >
                  {Array.isArray(queues) && queues.map((queue) => (
                    <MenuItem key={queue.id} value={queue.id}>
                      {queue.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
              >
                {i18n.t("transferTicketModal.buttons.cancel")}
              </Button>
              <ButtonWithSpinner
                variant="contained"
                type="submit"
                color="primary"
                loading={isSubmitting}
              >
                {i18n.t("transferTicketModal.buttons.ok")}
              </ButtonWithSpinner>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TransferTicketModalCustom;
