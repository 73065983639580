import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button, useTheme, withStyles, withWidth } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import DownloadIcon from "../../assets/icon/DownloadIcon";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import SearchIcon from "../../assets/icon/SearchIcon";
import AddIcon from "../../assets/icon/AddIcon";
import SwitchCustom from "../../components/SwitchCustom/SwitchCustom";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    background: theme.palette.background.default,
  },

  tabsHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px 15px 0 0",
    overflow: "hidden",
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      width: '100%'
    }
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  tabs: {
    height: 55,
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  tabsList: {
    height: 20,
    background: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  tab: {
    minWidth: 90,
    textTransform: "none",
    fontSize: "1.1rem",
    fontWeight: "500",
    [theme.breakpoints.down('md')]: {
      minWidth: 70,
      fontSize: "0.9rem",
      padding: theme.spacing(0.8),
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 90,
      fontSize: "1.1rem",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: "0.9rem",
      padding: theme.spacing(0.5),
    }
  },
  tabWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: '0 !important',
    },
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      "& > *": {
        marginRight: theme.spacing(0.5),
      },
      "& > *:first-child": {
        marginRight: theme.spacing(0.5),
      },
    }
  },

  tabWrapperList: {
    justifyContent: "left",
    alignItems: "center",
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
    marginRight: theme.spacing(1),
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1),
    background: theme.palette.background.default,
    [theme.breakpoints.down('md', 960)]: {
      padding: theme.spacing(0.5),
    }
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
    }
  },
  centerSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0.5),
    }
  },
  buttonNew: {
    borderRadius: 10,
    fontSize: 17,
    padding: theme.spacing(0.5, 3),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.3, 1.5),
      fontSize: 13,
      marginRight: 3,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0.5, 3),
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.3, 1.5),
      fontSize: 13,
      marginRight: 3,
    }
  },
  switchLabel: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    }
  },

  serachInputWrapper: {
    flex: 1,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
}));



const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
  }, []);

  useEffect(() => {
    if (tab === "search") {
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket && ticket.uuid) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    console.log(users)
    setSelectedUsers(users);
  };

  return (
    <div style={{
      borderRadius: "15px 30px 30px 0",
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
      }
    }}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab
            value={"open"}
            icon={<DownloadIcon fill={theme.palette.messageIcons} strokeWidth="1.8" />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          />
          {profile === "admin" && (
            <Tab
              value={"closed"}
            icon={<SuccessIcon fill={theme.palette.messageIcons} strokeWidth="1.8" />}
            label={i18n.t("tickets.tabs.closed.title")}
              classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
            />
          )}
          {profile === "admin" && (
            <Tab
              value={"search"}
            icon={<SearchIcon fill={theme.palette.messageIcons} strokeWidth="2.3" />}
            label={i18n.t("tickets.tabs.search.title")}
              classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
            />
          )}
        </Tabs>
      </Paper>
      <Paper 
        square 
        elevation={0} 
        className={`${classes.ticketOptionsBox} ${tab === "closed" ? classes.hide : ""}`}
      >
        <div className={classes.leftSection}>
          <TicketsQueueSelect
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(values) => setSelectedQueueIds(values)}
          />
        </div>
        <div className={classes.centerSection}>
          <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <FormControlLabel
                label={i18n.t("tickets.buttons.showAll")}
                labelPlacement="end"
                classes={{ label: classes.switchLabel }}
                control={
                  <SwitchCustom
                    checked={showAllTickets}
                    onChange={() => setShowAllTickets((prevState) => !prevState)}
                    name="showAllTickets"
                  />
                }
              />
            )}
          />
        </div>

        <div>
          <Button
            variant="outlined"
            className={classes.buttonNew}
            onClick={() => setNewTicketModalOpen(true)}
            startIcon={<AddIcon fill={theme.palette.primary.main} className={classes.iconAdd} />}
          >
            {i18n.t("ticketsManager.buttons.newTicket")}
          </Button>
        </div>
      </Paper>
      <TabPanel value={tab} name="open" >
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          className={classes.tabsList}
        >
          <Tab
            label={
              <div style={{ margin: -2 }}>
                {i18n.t("ticketsList.assignedHeader")}
              </div>
            }
            value={"open"}
            classes={{ root: classes.tab, wrapper: classes.tabWrapperList }}
          />
          <Tab
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{i18n.t("ticketsList.pendingHeader")}</span>
                <Badge
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                  style={{ marginLeft: '10px' }}
                />
              </div>
            }
            value={"pending"}
            classes={{ root: classes.tab, wrapper: classes.tabWrapperList }}
          />
        </Tabs>
      </TabPanel>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        {profile === "admin" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )}
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </div>
  );
};

export default TicketsManagerTabs;
