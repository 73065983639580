import { useHistory } from "react-router-dom";
import { parseISO, format } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    padding: theme.spacing(1.5),
    background: theme.palette.tabHeaderBackground,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
  leftHeaderSection: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  messageContent: {
    color: theme.palette.text.secondary,
    marginLeft: 3,
    fontSize: '0.9rem',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contactName: {
    color: theme.palette.primary.primary,
    fontWeight: 500
  },
  messageDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem'
  },
  unreadBadge: {
    backgroundColor: theme.palette.primary.main,
    color: "black",
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: 'bold'
  },
}));

const TicketListItem = ({ ticket, isSelected }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelectTicket = (ticket) => {
    history.push(`/tickets/${ticket.uuid}`);
  };

  return (
    <ListItem
      button
      onClick={() => handleSelectTicket(ticket)}
      className={clsx(classes.ticket, classes.listItem)}
    >
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.leftHeaderSection}>
            <Avatar 
              src={ticket?.contact?.profilePicUrl} 
              className={classes.avatar}
            />
            <div className={classes.infoContainer}>
              <Typography className={classes.contactName}>
                {ticket.contact.name}
              </Typography>
              <Typography className={classes.messageDate}>
                {format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}
              </Typography>
            </div>
          </div>
          {ticket.unreadMessages > 0 && (
            <div className={classes.unreadBadge}>
              {ticket.unreadMessages}
            </div>
          )}
        </div>
        <Typography className={classes.messageContent}>
          {ticket.lastMessage || "No hay mensajes aún."}
        </Typography>
      </div>
    </ListItem>
  );
};

export default TicketListItem;