const SquareSuccesIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="10" fill="#5BC64C" fill-opacity="0.16" />
      <path
        d="M17 24.5C21.125 24.5 24.5 21.125 24.5 17C24.5 12.875 21.125 9.5 17 9.5C12.875 9.5 9.5 12.875 9.5 17C9.5 21.125 12.875 24.5 17 24.5Z"
        stroke="#5BC64C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8125 16.9999L15.935 19.1224L20.1875 14.8774"
        stroke="#5BC64C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SquareSuccesIcon;
