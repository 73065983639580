const CircleIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3333 22C10.9499 22 9.64992 21.7375 8.43325 21.2125C7.21659 20.6875 6.15825 19.975 5.25825 19.075C4.35825 18.175 3.64575 17.1167 3.12075 15.9C2.59575 14.6833 2.33325 13.3833 2.33325 12C2.33325 10.6167 2.59575 9.31667 3.12075 8.1C3.64575 6.88333 4.35825 5.825 5.25825 4.925C6.15825 4.025 7.21659 3.3125 8.43325 2.7875C9.64992 2.2625 10.9499 2 12.3333 2C13.7166 2 15.0166 2.2625 16.2333 2.7875C17.4499 3.3125 18.5083 4.025 19.4083 4.925C20.3083 5.825 21.0208 6.88333 21.5458 8.1C22.0708 9.31667 22.3333 10.6167 22.3333 12C22.3333 13.3833 22.0708 14.6833 21.5458 15.9C21.0208 17.1167 20.3083 18.175 19.4083 19.075C18.5083 19.975 17.4499 20.6875 16.2333 21.2125C15.0166 21.7375 13.7166 22 12.3333 22ZM12.3333 20C14.5666 20 16.4583 19.225 18.0083 17.675C19.5583 16.125 20.3333 14.2333 20.3333 12C20.3333 9.76667 19.5583 7.875 18.0083 6.325C16.4583 4.775 14.5666 4 12.3333 4C10.0999 4 8.20825 4.775 6.65825 6.325C5.10825 7.875 4.33325 9.76667 4.33325 12C4.33325 14.2333 5.10825 16.125 6.65825 17.675C8.20825 19.225 10.0999 20 12.3333 20Z" fill="#7F7F7F"/>
</svg>


  );
};

export default CircleIcon;
