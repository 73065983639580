const StarEmptyIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5689 2.21889L13.1658 5.41272C13.3835 5.85731 13.9642 6.28376 14.4542 6.36542L17.3486 6.84631C19.1996 7.1548 19.6351 8.49766 18.3013 9.82237L16.0511 12.0726C15.67 12.4537 15.4613 13.1886 15.5793 13.7149L16.2235 16.5004C16.7316 18.7052 15.5611 19.5581 13.6104 18.4058L10.8974 16.7998C10.4075 16.5094 9.59993 16.5094 9.1009 16.7998L6.38796 18.4058C4.44626 19.5581 3.26672 18.6961 3.77483 16.5004L4.41904 13.7149C4.53699 13.1886 4.3283 12.4537 3.94722 12.0726L1.69703 9.82237C0.372313 8.49766 0.798762 7.1548 2.64973 6.84631L5.54414 6.36542C6.02502 6.28376 6.60572 5.85731 6.82348 5.41272L8.42039 2.21889C9.29144 0.485875 10.7069 0.485875 11.5689 2.21889Z" fill="#414141"/>
</svg>

  );
};

export default StarEmptyIcon;
