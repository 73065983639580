const SquareChatingIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="10" fill="#748E9D" fill-opacity="0.16" />
      <path
        d="M21.485 16.0925V19.0925C21.485 19.2875 21.4775 19.475 21.455 19.655C21.2825 21.68 20.09 22.685 17.8925 22.685H17.5925C17.405 22.685 17.225 22.775 17.1125 22.925L16.2125 24.125C15.815 24.6575 15.17 24.6575 14.7725 24.125L13.8725 22.925C13.775 22.7975 13.5575 22.685 13.3925 22.685H13.0925C10.7 22.685 9.5 22.0925 9.5 19.0925V16.0925C9.5 13.895 10.5125 12.7025 12.53 12.53C12.71 12.5075 12.8975 12.5 13.0925 12.5H17.8925C20.285 12.5 21.485 13.7 21.485 16.0925Z"
        stroke="#748E9D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.485 13.0925V16.0925C24.485 18.2975 23.4725 19.4825 21.455 19.655C21.4775 19.475 21.485 19.2875 21.485 19.0925V16.0925C21.485 13.7 20.285 12.5 17.8925 12.5H13.0925C12.8975 12.5 12.71 12.5075 12.53 12.53C12.7025 10.5125 13.895 9.5 16.0925 9.5H20.8925C23.285 9.5 24.485 10.7 24.485 13.0925Z"
        stroke="#748E9D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1216 17.9375H18.1284"
        stroke="#748E9D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4966 17.9375H15.5034"
        stroke="#748E9D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8716 17.9375H12.8784"
        stroke="#748E9D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SquareChatingIcon;
