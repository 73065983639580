import { createTheme } from "@material-ui/core/styles";

export const createAppTheme = (mode, locale) => {
    return createTheme(
        {
            typography: {
                fontFamily: '"DM Sans", sans-serif',
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 700,
            },
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: "#e8e8e8",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#FFFFFF" : "#242421",
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? "#F1B000" : "#FFE397" },
                secondary: { main: mode === "light" ? "#F1B000" : "#FFE397" },
                blackWhite: { main: mode === "light" ? "white" : "#000000" },
                textPrimary: mode === "light" ? "#212121" : "#FFFFFF",
                headerText: mode === "light"? "#7F7F7F": "#AEADAC",
                whiteText: "#FFFFFF",
                sideBarIcon:mode === "light" ?  "#AEADAC":  "#AEADAC",
                borderPrimary: mode === "light" ? "#000000" : "#FFFFFF",
                dark: { main: mode === "light" ? "#1B1B18" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#1B1B18" },
                tabHeaderBackground: mode === "light" ? "#FFFFFF" : "#292926",
                pageBackground: mode === "light" ? "#FFFFFF" : "#1B1B18",
                deleteButton: "#FF5858",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
                fancyBackground: mode === "light" ? "#fafafa" : "#1B1B18",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                sidebarBackground: mode === "light" ? "#1D1C18" : "#0C0B0A",
                inputBackground: mode === "light" ? "#FFFFFF" : "#242421",
                borderPhoneInput: mode === "light" ? "rgba(0, 0, 0, 0.3)":"rgba(255, 255, 255, 0.3)",
                borderTableInput: mode === "light" ? "rgba(0, 0, 0, 0.08)":"rgba(255, 255, 255, 0.08)",
                inputMessage: mode === "light" ? "rgba(59, 59, 55, 0.08)":"#222",
                text: { main: mode === "light" ? "#212121" : "#212121" },
                background: {
                    default: mode === "light" ? "#fafafa" : "#1B1B18",
                },
            },
            breakpoints: {
                values: {
                  xs: 0,
                  sm: 600,
                  md: 960,
                  lg: 1280,
                  xl: 1920,
                  ultra: 2560,  // Aquí defines el nuevo breakpoint "ultra"
                },
              },
            overrides: {
                MuiButton: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            mode,
        },
        locale
    );
};