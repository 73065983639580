const EyeIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z" stroke={fill}stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.00014 13.5133C10.3535 13.5133 12.5468 12.1266 14.0735 9.72665C14.6735 8.78665 14.6735 7.20665 14.0735 6.26665C12.5468 3.86665 10.3535 2.47998 8.00014 2.47998C5.64681 2.47998 3.45347 3.86665 1.92681 6.26665C1.32681 7.20665 1.32681 8.78665 1.92681 9.72665C3.45347 12.1266 5.64681 13.5133 8.00014 13.5133Z" stroke={fill}stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export default EyeIcon