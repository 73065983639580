import React, { useState, useEffect, useRef, useCallback } from "react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import Chart from "./Chart";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import PhoneIcon from "../../assets/icon/SquarePhoneIcon";
import SquarePhoneIcon from "../../assets/icon/SquarePhoneIcon";
import SquareChatingIcon from "../../assets/icon/SquareChatingIcon";
import SquareSuccesIcon from "../../assets/icon/SquareSuccesIcon";
import SquareUserIcon from "../../assets/icon/SquareUserIcon";
import SquareClockIcon from "../../assets/icon/SquareClockIcon";
import SquareCoffeIcon from "../../assets/icon/SquareCoffeIcon";
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import DownArrow from "../../assets/icon/DownArrow";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CustomChart from "./Chart";
import 'moment/locale/es';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 0, 4, 0),
    margin: theme.spacing(0, 5, 0, 5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 240,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  cardAvatar: {
    fontSize: "40px",
    color: "#ffffff",
    backgroundColor: "transparent",
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: 500,
  },
  cardSubtitle: {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: 600,
  },
  fullWidth: {
    width: "100%",
  },
  containerFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    borderRadius: "12px",
    backgroundColor: theme.palette.inputBackground,
    width: "100%",
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
  leftSection: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    flexGrow: 1,
  },
  formControl: {
    backgroundColor: "transparent",
    marginTop: theme.spacing(2),
    color: "#fff",
    borderRadius: "8px",
    minWidth: "200px",
    "& .MuiInputLabel-outlined": {
      color: "#888",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  datePicker: {
    marginTop: theme.spacing(2),
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-outlined": {
      color: "#888",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text,
      "&::-webkit-calendar-picker-indicator": {
        display: "none",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 0,
  },
  buttonFilter: {
    backgroundColor: "transparent",
    color: "#d4af37",
    border: "1px solid #d4af37",
    padding: theme.spacing(1, 2),
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#d4af37",
      color: "#000",
    },
  },
  cardCommon: {
    padding: theme.spacing(4, 0, 4, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    borderRadius: "12px",
    backgroundColor: theme.palette.inputBackground,
    color: theme.palette.text.secondary,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [value, setValue] = useState('periodo');
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();
  const { finding } = useCompanies();
  const [startDate, setStartDate] = useState(new Date("2024-08-01"));
  const [endDate, setEndDate] = useState(new Date("2024-08-15"));

  useEffect(() => {
    fetchData();
  }, [period, dateFrom, dateTo]);

  const handleChangePeriod = useCallback((value) => {
    setPeriod(value);
  }, []);

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  const renderDateFilters = () => (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Fecha Inicial"
          type="date"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          className={classes.fullWidth}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Fecha Final"
          type="date"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          className={classes.fullWidth}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </>
  );

  const renderPeriodFilters = () => (
    <Grid item xs={12} sm={6} md={4}>
      <FormControl className={classes.selectContainer}>
        <InputLabel id="period-selector-label">Período</InputLabel>
        <Select
          labelId="period-selector-label"
          id="period-selector"
          value={period}
          onChange={(e) => handleChangePeriod(e.target.value)}
        >
          <MenuItem value={0}>Ninguno seleccionado</MenuItem>
          <MenuItem value={3}>Últimos 3 días</MenuItem>
          <MenuItem value={7}>Últimos 7 días</MenuItem>
          <MenuItem value={15}>Últimos 15 días</MenuItem>
          <MenuItem value={30}>Últimos 30 días</MenuItem>
          <MenuItem value={60}>Últimos 60 días</MenuItem>
          <MenuItem value={90}>Últimos 90 días</MenuItem>
        </Select>
        <FormHelperText>Seleccione el período deseado</FormHelperText>
      </FormControl>
    </Grid>
  );

  const renderFilters = () =>
    filterType === 1 ? renderDateFilters() : renderPeriodFilters();

  const renderStatisticCard = (title, value, icon) => (
    <Grid item xs={12} sm={6} md={2}>
      <Paper className={classes.cardCommon} style={{ overflow: "hidden" }}>
        <Grid>
          <Grid item xs={8}>
            {icon}
            <Typography
              component="h3"
              variant="h6"
              style={{ fontSize: 15, marginTop: 5 }}
            >
              {title}
            </Typography>
            <Typography
              component="h1"
              variant="h4"
              style={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
                fontSize: 33,
              }}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  useEffect(() => {
    // Configurar moment en español
    moment.locale('es');
  }, []);

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        {renderStatisticCard(
          "Att. Colgantes",
          counters.supportPending,
          <SquarePhoneIcon />
        )}
        {renderStatisticCard(
          "Att. Sucediendo",
          counters.supportHappening,
          <SquareChatingIcon />
        )}
        {renderStatisticCard(
          "Finalizado",
          counters.supportFinished,
          <SquareSuccesIcon />
        )}
        {renderStatisticCard(
          "Nuevos contactos",
          counters.leads,
          <SquareUserIcon />
        )}
        {renderStatisticCard(
          "T.M. de servicio",
          formatTime(counters.avgSupportTime),
          <SquareClockIcon />
        )}
        {renderStatisticCard(
          "T.M. espera",
          formatTime(counters.avgWaitTime),
          <SquareCoffeIcon />
        )}

        <Grid container className={classes.containerFilter}>
          <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="filter-type-label">Periodo por tiempo</InputLabel>
      <Select
        labelId="filter-type-label"
        id="filter-type-select"
        value={value}
        onChange={handleChangePeriod}
        label="Periodo por tiempo"
        className={classes.select}
        MenuProps={{
          classes: {
            paper: classes.paper,
            list: classes.list,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="periodo">Período por tiempo</MenuItem>
        <MenuItem value="fecha">Filtro por fecha</MenuItem>
      </Select>
    </FormControl>

            <MuiPickersUtilsProvider 
              utils={MomentUtils} 
              locale="es"
            >
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Fecha inicial"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "cambiar fecha"
                }}
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                invalidDateMessage="Fecha inválida"
                maxDateMessage="La fecha no puede ser posterior a la máxima"
                minDateMessage="La fecha no puede ser anterior a la mínima"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton size="small">
                      <DownArrow />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "#888" },
                }}
              />
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Fecha final"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "cambiar fecha"
                }}
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                invalidDateMessage="Fecha inválida"
                maxDateMessage="La fecha no puede ser posterior a la máxima"
                minDateMessage="La fecha no puede ser anterior a la mínima"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton size="small">
                      <DownArrow />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "#888" },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              className={classes.buttonFilter}
              onClick={() => fetchData()}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ width: "100%", margin: "0 auto" }}>
            <CustomChart />
          </div>
        </Grid>

        <Grid item xs={12}>
          {attendants.length > 0 && (
            <TableAttendantsStatus attendants={attendants} loading={loading} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
