const SquarePhoneIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="10" fill="#0094BB" fill-opacity="0.16" />
      <path
        d="M24.4775 21.7475C24.4775 22.0175 24.4175 22.295 24.29 22.565C24.1625 22.835 23.9975 23.09 23.78 23.33C23.4125 23.735 23.0075 24.0275 22.55 24.215C22.1 24.4025 21.6125 24.5 21.0875 24.5C20.3225 24.5 19.505 24.32 18.6425 23.9525C17.78 23.585 16.9175 23.09 16.0625 22.4675C15.2 21.8375 14.3825 21.14 13.6025 20.3675C12.83 19.5875 12.1325 18.77 11.51 17.915C10.895 17.06 10.4 16.205 10.04 15.3575C9.68 14.5025 9.5 13.685 9.5 12.905C9.5 12.395 9.59 11.9075 9.77 11.4575C9.95 11 10.235 10.58 10.6325 10.205C11.1125 9.7325 11.6375 9.5 12.1925 9.5C12.4025 9.5 12.6125 9.545 12.8 9.635C12.995 9.725 13.1675 9.86 13.3025 10.055L15.0425 12.5075C15.1775 12.695 15.275 12.8675 15.3425 13.0325C15.41 13.19 15.4475 13.3475 15.4475 13.49C15.4475 13.67 15.395 13.85 15.29 14.0225C15.1925 14.195 15.05 14.375 14.87 14.555L14.3 15.1475C14.2175 15.23 14.18 15.3275 14.18 15.4475C14.18 15.5075 14.1875 15.56 14.2025 15.62C14.225 15.68 14.2475 15.725 14.2625 15.77C14.3975 16.0175 14.63 16.34 14.96 16.73C15.2975 17.12 15.6575 17.5175 16.0475 17.915C16.4525 18.3125 16.8425 18.68 17.24 19.0175C17.63 19.3475 17.9525 19.5725 18.2075 19.7075C18.245 19.7225 18.29 19.745 18.3425 19.7675C18.4025 19.79 18.4625 19.7975 18.53 19.7975C18.6575 19.7975 18.755 19.7525 18.8375 19.67L19.4075 19.1075C19.595 18.92 19.775 18.7775 19.9475 18.6875C20.12 18.5825 20.2925 18.53 20.48 18.53C20.6225 18.53 20.7725 18.56 20.9375 18.6275C21.1025 18.695 21.275 18.7925 21.4625 18.92L23.945 20.6825C24.14 20.8175 24.275 20.975 24.3575 21.1625C24.4325 21.35 24.4775 21.5375 24.4775 21.7475Z"
        stroke="#0094BB"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default SquarePhoneIcon;
