import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ScheduleModal from "../../components/ScheduleModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import moment from 'moment';
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import TrashIcon from "../../assets/icon/TrashIcon";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import AddIcon from "../../assets/icon/AddIcon";


const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    return action.payload;
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      const newState = [...state];
      newState[scheduleIndex] = schedule;
      return newState;
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;
    return state.filter(s => s.id !== scheduleId);
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));

const Schedules = () => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(+getUrlParam("contactId"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchSchedules = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/schedules/", {
        params: { 
          searchParam, 
          pageNumber: page + 1, 
          pageSize: rowsPerPage 
        },
      });

      const localSchedules = data.schedules.map(schedule => ({
        ...schedule,
        sendAt: moment(schedule.sendAt).local().format('YYYY-MM-DD HH:mm:ss')
      }));

      dispatch({ type: "LOAD_SCHEDULES", payload: localSchedules });
      setCount(data.count);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    dispatch({ type: "RESET" });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({ type: "RESET" });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(0);
  }, [searchParam]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules, searchParam, page, rowsPerPage]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedules });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
      dispatch({ type: "DELETE_SCHEDULE", payload: scheduleId });
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    setConfirmModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const columns = [
    { field: 'contact.name', headerName: i18n.t("schedules.table.contact") },
    {
      field: 'body',
      headerName: i18n.t("schedules.table.body"),
    },
    {
      field: 'sendAt',
      headerName: i18n.t("schedules.table.sendAt"),
      render: (row) => {
        return moment(row.sendAt).local().format('DD/MM/YYYY HH:mm');
      }
    },
    {
      field: 'status',
      headerName: i18n.t("schedules.table.status"),
    },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleEditSchedule(row)}
        disabled={row.status === "ERROR"}
        style={{ opacity: row.status === "ERROR" ? 0.5 : 1 }}
      >
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingSchedule(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  const primaryButtons = [
    { label: i18n.t("schedules.buttons.add"), onClick: handleOpenScheduleModal, icon: <AddIcon fill='#000' /> },
  ];

  const reloadSchedules = useCallback(async () => {
    dispatch({ type: "RESET" });
    setLoading(true);
    try {
      const { data } = await api.get("/schedules/", {
        params: { 
          searchParam, 
          pageNumber: page + 1, 
          pageSize: rowsPerPage 
        },
      });

      const localSchedules = data.schedules.map(schedule => ({
        ...schedule,
        sendAt: moment(schedule.sendAt).local().format('YYYY-MM-DD HH:mm:ss')
      }));

      dispatch({ type: "LOAD_SCHEDULES", payload: localSchedules });
      setCount(data.count);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  }, [searchParam, page, rowsPerPage]);

  return (
    <>
      <div className={classes.mainContainer}  >
        <ConfirmationModal
          title={deletingSchedule && i18n.t("schedules.confirmationModal.deleteTitle")}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
        >
          {i18n.t("schedules.confirmationModal.deleteMessage")}
        </ConfirmationModal>

        <ScheduleModal
          open={scheduleModalOpen}
          onClose={handleCloseScheduleModal}
          reload={reloadSchedules}
          scheduleId={selectedSchedule && selectedSchedule.id}
          contactId={contactId}
        />

        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("schedules.title")}
            primaryButtons={primaryButtons}
            onSearch={handleSearch}
          />

          <TableManager
            columns={columns}
            data={schedules}
            loading={loading}
            onScroll={handleScroll}
            actionButtons={actionButtons}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            hasPagination={true}
          />
        </div>
      </div>
    </>
  );
};

export default Schedules;