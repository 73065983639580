import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import DownArrow from "../../assets/icon/DownArrow";
import { CheckCircleOutline } from "@material-ui/icons";
import { Checkbox, List, ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	chipsContainer: {
		display: "flex",
		overflowX: "auto",
		maxWidth: "100%",
		padding: "2px 0",
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	select: {
	  "&.MuiOutlinedInput-root": {
		"& fieldset": {
		  borderColor: "#00000014",
		},
		"&:hover fieldset": {
		  borderColor: "#00000014",
		},
		"&.Mui-focused fieldset": {
		  borderColor: "#00000014",
		},
	  },
	},
	loadingContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: theme.spacing(2),
	},
	list: {
		width: '100%',
		maxHeight: 300,
		overflow: 'auto',
	},
	listItem: {
		padding: theme.spacing(0, 2),
	},
	icon: {
		right: theme.spacing(1),
		position: "absolute",
		pointerEvents: "none",
	},
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(1),
		'& .MuiSvgIcon-root': {
			display: 'none'
		}
	},
	selectedItem: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	}
}));

const QueueSelect = ({ selectedQueueIds, onChange }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [queues, setQueues] = useState([]);
	const [loading, setLoading] = useState(true);
  
	useEffect(() => {
		const fetchQueues = async () => {
			try {
				const { data } = await api.get("/queue");
				setQueues(Array.isArray(data.queues) ? data.queues : []);
			} catch (err) {
				toastError(err);
				setQueues([]);
			} finally {
				setLoading(false);
			}
		};
		fetchQueues();
	}, []);
  
	const handleChange = (event, queueId) => {
		const newSelectedIds = selectedQueueIds.includes(queueId)
			? selectedQueueIds.filter(id => id !== queueId)
			: [...selectedQueueIds, queueId];
		onChange(newSelectedIds);
	};
  
	const selectedQueues = useMemo(() => 
		queues.filter(queue => selectedQueueIds.includes(queue.id)),
		[queues, selectedQueueIds]
	);
  
	return (
		<div style={{ marginTop: "2px" }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
				{loading ? (
					<div className={classes.loadingContainer}>
						<CircularProgress size={24} />
					</div>
				) : (
					<Select
						multiple
						labelWidth={120}
						value={selectedQueueIds}
						className={classes.select}
						renderValue={() => (
							<div className={classes.chipsContainer}>
								{selectedQueues.map((queue) => (
									<Chip
										key={queue.id}
										style={{ backgroundColor: queue.color }}
										variant="outlined"
										label={queue.name}
										className={classes.chip}
									/>
								))}
							</div>
						)}
						IconComponent={() => (
							<div className={classes.icon}>
								<DownArrow fill={theme.palette.messageIcons} />
							</div>
						)}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
							getContentAnchorEl: null,
						}}
					>
						<List className={classes.list}>
							{queues.map((queue) => {
								return (
									<ListItem
										key={queue.id}
										onClick={(event) => handleChange(event, queue.id)}
										button
										dense
										className={classes.listItem}
									>
										<ListItemText primary={queue.name} />
									</ListItem>
								);
							})}
						</List>
					</Select>
				)}
			</FormControl>
		</div>
	);
};

export default QueueSelect;
