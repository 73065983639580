import { Chip, Paper, TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Skeleton } from "@material-ui/lab";


export function TagsContainer({ ticket }) {
    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(true);
    const theme = useTheme();

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            setLoading(true);
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    setSelecteds(ticket.tags);
                } else {
                    setSelecteds([]);
                }
                setLoading(false);
            });
        }
    }, [ticket]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item })
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: optionsChanged });
    }

    return (
        <div style={{ padding: 12, backgroundColor: theme.palette.background.default, borderRadius: '15px', boxShadow: '0 1px 1px rgba(0,0,0,0.10)', }}>
            {loading ? (
                <Skeleton variant="rect" width="100%" height={20} />
            ) : (
                <Autocomplete
                    multiple
                    size="small"
                    options={tags}
                    value={selecteds}
                    freeSolo={false}
                    onChange={(e, value) => {
                        setSelecteds(value);
                        syncTags({ ticketId: ticket.id, tags: value });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                style={{
                                    backgroundColor: option.color || '#2563eb',
                                    color: 'white',
                                    margin: '2px',
                                    borderRadius: '9999px',
                                    padding: 5
                                }}
                                label={option.name}
                                {...getTagProps({ index })}
                                size="small"
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Seleccionar etiquetas existentes..."
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: { color: theme.palette.text.blackWhite }
                            }}
                        />
                    )}
                    renderOption={(option) => (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <div style={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: option.color || '#2563eb'
                            }} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    PaperComponent={({ children }) => (
                        <Paper style={{ 
                            color: theme.palette.text.secondary, 
                            backgroundColor: theme.palette.background.default 
                        }}>
                            {children}
                        </Paper>
                    )}
                />
            )}
        </div>
    )
}