import { Rating } from "@material-ui/lab";
import StarEmptyIcon from "../../assets/icon/StarEmptyIcon";
import StarFullIcon from "../../assets/icon/StarFullIcon";

export function RatingBox({ rating }) {
  const ratingTrunc = rating === null ? 0 : Math.trunc(rating);

  return (
    <Rating
      value={ratingTrunc}
      max={3}
      emptyIcon={<StarEmptyIcon />}
      icon={<StarFullIcon />}       
      readOnly
    />
  );
}