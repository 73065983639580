import React, { useState, useEffect, useRef, useMemo } from "react";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler } from 'chart.js';
import { startOfHour, parseISO, format } from "date-fns";
import { useTheme } from "@material-ui/core";
import useTickets from "../../hooks/useTickets";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

const CustomChart = React.memo(() => {
  const date = useRef(new Date().toISOString());
  const theme = useTheme();

  const { tickets } = useTickets({ date: date.current });

  const initialChartData = useMemo(() => (
    Array(24).fill().map((_, index) => ({
      time: format(new Date().setHours(index, 0, 0, 0), "HH:mm"),
      amount: 0
    }))
  ), []);

  const [chartData, setChartData] = useState(initialChartData);

  useEffect(() => {
    if (tickets.length > 0) {
      setChartData(prevState => {
        return prevState.map(dataPoint => ({
          ...dataPoint,
          amount: tickets.filter(ticket => 
            format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === dataPoint.time
          ).length
        }));
      });
    }
  }, [tickets]);

  const data = {
    labels: chartData.map(d => d.time),
    datasets: [
      {
      fill: true,
      data: chartData.map(d => d.amount),
      borderColor: 'rgba(255, 206, 86, 1)',
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
    
        if (!chartArea) {
        return null;
        }
    
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, 'rgba(255, 206, 86, 0.5)');
        gradient.addColorStop(1, 'rgba(255, 206, 86, 0)');
    
        return gradient;
      },
      tension: 0.4,
      pointRadius: 0,
      },
    ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: theme.palette.text.secondary,
            font: {
              size: 10,
            },
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 12,
          },
        },
        y: {
          display: false,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
      },
    };
  

  const totalTickets = chartData.reduce((sum, dataPoint) => sum + dataPoint.amount, 0);

  return (
    <div style={{ 
      backgroundColor: theme.palette.inputBackground, 
      padding: '20px', 
      borderRadius: '10px',
      color: '#fff',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div style={{ marginBottom: '10px' }}>
        <div style={{ fontSize: '12px', opacity: 0.7, color: theme.palette.text.secondary }}>Tickets hoy</div>
        <div style={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.primary }}>{totalTickets}</div>
      </div>
      <div style={{ height: '150px' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
});

export default CustomChart;
