import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, TextField, DialogContent, DialogActions, Grid, Popover, useMediaQuery, InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropType from 'prop-types'
import Dialog from '../Dialog';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { i18n } from '../../translate/i18n';
import { makeStyles } from '@material-ui/core/styles';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { AuthContext } from "../../context/Auth/AuthContext";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

import { isNil, isObject, has, get } from 'lodash';
import EmojiPicker from 'emoji-picker-react';

import api from '../../services/api'; // Asegúrate de importar tu servicio de API

const MessageSchema = Yup.object().shape({
    shortcode: Yup.string()
        .min(3, "Muy corto!")
        .max(50, "Demasiado largo!")
        .required("Requerido"),
    message: Yup.string()
        .min(3, "Muy corto!")
        .max(500, "Demasiado largo!")
        .required("Requerido")
});


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    emojiButton: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },  
    },
    emojiPickerStyle: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            width: '100%',
        },
    },
    messageField: {
        '& .MuiInputBase-root': {
            paddingRight: 0,
        },
    },
}));

function QuickMessageDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const initialMessage = {
        id: null,
        shortcode: '',
        message: ''
    };

    const { modalOpen, saveMessage, editMessage, onClose, messageSelected } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState(initialMessage);
    const [loading, setLoading] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const messageRef = useRef(null);
    const shortcodeRef = useRef(null); 
    const emojiPickerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const emojiButtonRef = useRef(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        verifyAndSetMessage()
        setDialogOpen(modalOpen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen])

    useEffect(() => {
        verifyAndSetMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageSelected])

    const messageSelectedIsValid = () => {
        return isObject(messageSelected) && has(messageSelected, 'id') && !isNil(get(messageSelected, 'id'))
    }

    const verifyAndSetMessage = () => {
        if (messageSelectedIsValid()) {
            const { id, message, shortcode } = messageSelected
            setMessage({ id, message, shortcode })
        } else {
            setMessage(initialMessage)
        }
    }

    const handleClose = () => {
        onClose()
        setLoading(false)
    }

    const handleSave = async (values, { setSubmitting }) => {
        setLoading(true);
        try {
            if (messageSelectedIsValid()) {
                await editMessage({
                    ...messageSelected,
                    ...values,
                    userId: user.id
                });
            } else {
                await saveMessage({
                    ...values,
                    userId: user.id
                });
            }
            handleClose();
        } catch (error) {
            console.error('Error al guardar el mensaje rápido:', error);
            // Aquí podrías mostrar un mensaje de error al usuario
            toast.error('Error al guardar el mensaje rápido');
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    }
    

    const handleEmojiButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleEmojiPickerClose = () => {
        setAnchorEl(null);
    };

    const handleEmojiClick = (emojiObject, setFieldValue) => {
        const emoji = emojiObject.emoji || emojiObject.native || emojiObject;
        const field = messageRef.current;
        const currentShortcode = shortcodeRef.current.value;
      
        if (field) {
            const start = field.selectionStart || 0;
            const end = field.selectionEnd || 0;
            const text = field.value || "";
            const newText = text.substring(0, start) + emoji + text.substring(end);

            setFieldValue("message", newText);
            setFieldValue("shortcode", currentShortcode);
            field.focus();
            setTimeout(() => {
                field.setSelectionRange(start + emoji.length, start + emoji.length);
            }, 0);
        }
        handleEmojiPickerClose();
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <Dialog
                title="Mensaje Rápido"
                modalOpen={dialogOpen}
                onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm"
            >
                <Formik
                    initialValues={message}
                    validationSchema={MessageSchema}
                    onSubmit={handleSave}
                >
                    {({ touched, errors, isSubmitting, setFieldValue, isValid, dirty }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="shortcode"
                                            label={i18n.t("quickMessages.dialog.shortcode")}
                                            error={touched.shortcode && Boolean(errors.shortcode)}
                                            helperText={touched.shortcode && errors.shortcode}
                                            variant="outlined"
                                            inputRef={shortcodeRef}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="message"
                                            inputRef={messageRef}
                                            rows={6}
                                            label={i18n.t("quickMessages.dialog.message")}
                                            multiline
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                            variant="outlined"
                                            fullWidth
                                            className={classes.messageField}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            ref={emojiButtonRef}
                                                            color="primary"
                                                            className={classes.emojiButton}
                                                            onClick={handleEmojiButtonClick}
                                                        >
                                                            <InsertEmoticonIcon style={{ color: "black"}} />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={handleClose} 
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Cancelar
                                </Button>
                                <ButtonWithSpinner 
                                    loading={isSubmitting} 
                                    color="primary" 
                                    type="submit" 
                                    variant="contained" 
                                    autoFocus
                                    disabled={isSubmitting || !isValid || !dirty}
                                >
                                    Guardar
                                </ButtonWithSpinner>
                            </DialogActions>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleEmojiPickerClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className={classes.emojiPickerStyle}>
                                    <EmojiPicker
                                        onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject, setFieldValue)}
                                        searchPlaceholder="Buscar emojis"
                                        categories={{
                                            smileys_people: 'Caras y personas',
                                            animals_nature: 'Animales y naturaleza',
                                            food_drink: 'Comida y bebida',
                                            travel_places: 'Viajes y lugares',
                                            activities: 'Actividades',
                                            objects: 'Objetos',
                                            symbols: 'Símbolos',
                                            flags: 'Banderas'
                                        }}
                                    />
                                </div>
                            </Popover>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

QuickMessageDialog.propType = {
    modalOpen: PropType.bool,
    onClose: PropType.func
}

export default QuickMessageDialog;
