const SquareUserIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="10" fill="#7F78E6" fill-opacity="0.16"/>
    <path d="M17.1199 16.1525C17.0449 16.145 16.9549 16.145 16.8724 16.1525C15.0874 16.0925 13.6699 14.63 13.6699 12.83C13.6699 10.9925 15.1549 9.5 16.9999 9.5C18.8374 9.5 20.3299 10.9925 20.3299 12.83C20.3224 14.63 18.9049 16.0925 17.1199 16.1525Z" stroke="#7F78E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.37 18.92C11.555 20.135 11.555 22.115 13.37 23.3225C15.4325 24.7025 18.815 24.7025 20.8775 23.3225C22.6925 22.1075 22.6925 20.1275 20.8775 18.92C18.8225 17.5475 15.44 17.5475 13.37 18.92Z" stroke="#7F78E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};

export default SquareUserIcon;
