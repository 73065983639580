import React, { useEffect, useReducer, useState } from "react";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import QueueModal from "../../components/QueueModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { socketConnection } from "../../services/socket";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import AddIcon from "../../assets/icon/AddIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_QUEUES":
      return Array.isArray(action.payload) ? [...action.payload] : state;
    case "UPDATE_QUEUES":
      const queueIndex = state.findIndex(queue => queue.id === action.payload.id);
      if (queueIndex !== -1) {
        return state.map(queue => queue.id === action.payload.id ? action.payload : queue);
      } else {
        return [action.payload, ...state];
      }
    case "DELETE_QUEUE":
      return state.filter(queue => queue.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const Queues = () => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [queues, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchQueues();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-queue`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchQueues = async () => {
    try {
      const { data } = await api.get("/queue", {
        params: { searchParam, pageNumber },
      });
      console.log(data.queues)
      dispatch({ type: "LOAD_QUEUES", payload: data.queues });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      console.log(err)
      toastError(err);
    }
  };

  const handleOpenQueueModal = () => {
    setSelectedQueue(null);
    setQueueModalOpen(true);
  };

  const handleCloseQueueModal = () => {
    setSelectedQueue(null);
    setQueueModalOpen(false);
    fetchQueues(); // Refetch queues after closing the modal
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toast.success(i18n.t("queues.toast.delete"));
      dispatch({ type: "DELETE_QUEUE", payload: queueId });
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
    setConfirmModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const primaryButtons = [
    { label: i18n.t("queues.buttons.add"), onClick: handleOpenQueueModal, icon: <AddIcon fill='#000' /> },
  ];

  const columns = [
    { field: 'name', headerName: i18n.t("queues.table.name"), headerStyle: { fontWeight: '400' }, width: '28%' },
    {
      field: 'color',
      headerName: i18n.t("queues.table.color"),
      renderCell: ({ row }) => (
        <div style={{
          backgroundColor: row.color,
          width: 60,
          height: 20,
          alignSelf: "center",
        }} />
      ),
      headerStyle: { fontWeight: '400' }, width: '28%'
    },
    {
      field: 'greetingMessage',
      headerName: i18n.t("queues.table.greeting"),
      renderCell: ({ row }) => (
        <div style={{ width: 300, textAlign: "left", overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.greetingMessage}
        </div>
      ),
      headerStyle: { fontWeight: '400' }, width: '28%'
    },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton size="small" onClick={() => handleEditQueue(row)}>
        {console.log("array es ", row)}
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setSelectedQueue(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={selectedQueue && `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name}?`}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteQueue(selectedQueue.id)}
        >
          {i18n.t("queues.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <QueueModal
          open={queueModalOpen}
          onClose={handleCloseQueueModal}
          queueId={selectedQueue?.id}
          onQueueCreated={(newQueue) => {
            dispatch({ type: "UPDATE_QUEUES", payload: newQueue });
            setQueueModalOpen(false);
          }}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("queues.title")}
            primaryButtons={primaryButtons}
            onSearch={handleSearch}
          />
          <TableManager
            columns={columns}
            data={queues}
            loading={loading}
            onScroll={loadMore}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Queues;
