const PaperClipIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.97754 9.5V12.125C8.97754 13.5725 10.155 14.75 11.6025 14.75C13.05 14.75 14.2275 13.5725 14.2275 12.125V8C14.2275 5.0975 11.88 2.75 8.97754 2.75C6.07504 2.75 3.72754 5.0975 3.72754 8V12.5C3.72754 14.9825 5.74504 17 8.22754 17"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PaperClipIcon;
