import React from "react";

import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles(theme => ({
	ticketHeader: {
		display: "flex",
		background: theme.palette.background.default,
		borderRadius:15,
		flex: "none",
		[theme.breakpoints.down('md')]: {
			borderRadius:0,
		},
		padding: theme.spacing(0.8),
		marginBottom: theme.spacing(1),
		boxShadow: '0 1px 1px 0 rgba(0,0,0,0.10)',
	},
}));

const TicketHeader = ({ loading, children }) => {
	const classes = useStyles();

	return (
		<>
			{loading ? (
				<TicketHeaderSkeleton />
			) : (
				<Card square className={classes.ticketHeader}>
					{children}
				</Card>
			)}
		</>
	);
};

export default TicketHeader;
